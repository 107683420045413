<script lang="ts">
</script>

<main>
    <div class="background">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2202.007"
            height="984.492"
            ><path
                d="M 41.164 55.843 C 78.581
        45.008 333.512 76.091 614.726 63.827 C 895.94 51.564 1024.731 -4.765
        1247.176 0.575 C 1467.636 -11.036 1923.053 157.542 2034.252 115.73 C
        2145.45 73.917 2186.829 125.312 2186.829 125.312 L 2202.007 984.492 L
        16.4 984.492 L 16.4 55.843 C 16.4 55.843 -33.67 77.512 41.164 55.843 Z"
                fill="rgb(248, 248, 246)"
                stroke-width="1.59"
                stroke="rgba(255, 255,
        255, 0)"
            /></svg
        >
    </div>
    <div class="foreground">
        <div class="header">
            <div>
                <div class="title">Fruitronics</div>
                <h3>Mango Fruit Maturation Heat Units Calculator</h3>
            </div>
            <a href="/calculator">Enter Calculator</a>
        </div>
        <div class="content">
            <div class="images">
                <div>
                    <img
                        src="https://fruitmaps.info/assets/asparagus.jpg"
                        alt="Asparagus"
                    />
                    <p>Flowering stage: Asparagus</p>
                </div>
                <div>
                    <img
                        src="https://fruitmaps.info/assets/christmasTree.jpg"
                        alt="Christmas tree"
                    />
                    <p>Flowering stage: Christmas tree</p>
                </div>
            </div>
            <p>
                Enter flowering date and target heat units by cultivar and
                flowering stage, select a sensor location and a calculation
                method and receive predicted harvest date. This is calculated on
                historical average temperature data from the site, updated daily
                with min and max temperatures from that site. Two formulae for
                calculation of heat units are provided – the Australian standard
                method (with base temperature of 12 oC) and a method that
                assumes that fruit maturation slows at high temperatures. We
                recommend the second method, but try it to see the difference
                (not much in cooler grower growing areas, more in hotter
                regions).
            </p>

            <p>
                This site has a public and an account holder version. Tier 1 is
                a public site (no log in required) allows calculation of heat
                units using data from a set of temperature loggers on
                representative farms in each major mango growing district.
                Entered data and predicted dates will be lost when the browser
                is closed.
            </p>

            <p>
                Tier 2 and 3 require a log in (using a Google account). In Tier
                2, inputs are saved for the season, with predictions updated
                with each log on for current season temperatures. Entered data
                is also saved by year, so you can check your records from
                previous seasons. Tier 3 has the extra feature of allowing farm
                specific sensors to be linked to orchards. Tier 2 and 3 is free
                for 2021/22 but may be charged in future seasons.
            </p>
            <a href="/calculator">Enter Calculator</a>
        </div>
    </div>
</main>

<style lang="scss">
    div {
        display: flex;
    }
    main {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .background {
            position: absolute;
            width: 100vw;
            height: 100vh;
            overflow: hidden;
            z-index: -1;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                margin-top: 840px;
                transform: scale(1.5);
            }
        }

        .foreground {
            flex-direction: column;
            align-items: center;

            @media (max-width: 1000px) {
                margin-top: 50em;
            }

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                /* position: relative; */
                /* justify-content: space-between; */
                /* top: -62px; */
                /* width: 1130px; */


                div {
                    display: flex;
                    flex-direction: column;

                    .title {
                        font-size: 3em;
                    }
                }

                a {
                    margin-top: 22px;
                    text-decoration: none;
                    background: var(--bg);
                    padding: 0.8em 1.5em;
                    border-radius: 1000px;
                    color: black;

                    &:hover {
                        background: #dbd6cc;
                    }
                }
            }

            .content {
                flex-direction: column;
                width: 60vw;

                .images {
                    justify-content: center;

                    div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        img {
                            width: 275px;
                            margin: 1em;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
</style>
